import React, { useState } from "react";
import "../../styles/payment.css";
import PayTMForm from "./PayTMForm";
import UPIForm from "./UPIForm";
import NetBankingForm from "./NetBankingForm";
import DebitCardForm from "./DebitCardForm";
import CreditCardForm from "./CreditCardForm";

const PaymentPage = () => {
  const [paymentMethod, setPaymentMethod] = useState("creditCard");

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  return (
    <div className="payment-page">
      <div className="payment-methods">
        <button
          onClick={() => handlePaymentMethodChange("creditCard")}
          className={paymentMethod === "creditCard" ? "active" : ""}
        >
          Credit Card
        </button>
        <button
          onClick={() => handlePaymentMethodChange("debitCard")}
          className={paymentMethod === "debitCard" ? "active" : ""}
        >
          Debit Card
        </button>
        <button
          onClick={() => handlePaymentMethodChange("netBanking")}
          className={paymentMethod === "netBanking" ? "active" : ""}
        >
          Net Banking
        </button>
        <button
          onClick={() => handlePaymentMethodChange("upi")}
          className={paymentMethod === "upi" ? "active" : ""}
        >
          UPI
        </button>
        <button
          onClick={() => handlePaymentMethodChange("paytm")}
          className={paymentMethod === "paytm" ? "active" : ""}
        >
          PayTM
        </button>
      </div>
      <div className="payment-details">
        {paymentMethod === "creditCard" && <CreditCardForm />}
        {paymentMethod === "debitCard" && <DebitCardForm />}
        {paymentMethod === "netBanking" && <NetBankingForm />}
        {paymentMethod === "upi" && <UPIForm />}
        {paymentMethod === "paytm" && <PayTMForm />}
      </div>
    </div>
  );
};

export default PaymentPage;
