import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Message from "./messages";
import * as Yup from "yup";
import "../../styles/LoginPage.css";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import CookieService from "encrypted-cookie";

import { UserContext } from "../../context/UserContext";

const backenduRL = process.env.REACT_APP_API_UR;
const encryptionSecret = "secret-key1234"; // Replace with your secret key

console.log("backend", backenduRL);
const emailPasswordValidationSchema = Yup.object({
  email: Yup.string().required("Email is required"),
  password: Yup.string().min(6, "Password must be at least 6 characters"),
});

const otpValidationSchema = Yup.object({
  email: Yup.string().required("Email or mobile Number is required"),
  otp: Yup.string().required("OTP is required"),
});

const LoginPage = () => {
  const { setUserDetails } = useContext(UserContext);
  const [backendmessages, setBackendmessages] = useState(null);
  const [signOtp, setSignOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const navigate = useNavigate();

  const formikEmailPassword = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: emailPasswordValidationSchema,
    onSubmit: async (values, action) => {
      console.log("Submitting email/password form:", values);

      try {
        const response = await fetch(`/api/auth/signin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        const result = await response.json();

        if (response.ok) {
          CookieService.setCookie("token", result.token, 2);
          setBackendmessages({ success: result.message });
          action.resetForm();
          setUserDetails(result.existingUserAgg);
          localStorage.setItem(
            "personal",
            JSON.stringify(result.existingUserAgg)
          );
          if (result.images) {
            localStorage.setItem("userimages", JSON.stringify(result.images));
          }
          if (!result.existingUserAgg) {
            navigate("/ProfileCreating");
          } else {
            if (result.existingUserAgg.MembershipStatus === "Paid") {
              setTimeout(() => {
                navigate("/dashboard");
              }, 1500);
              return;
            }
            setTimeout(() => {
              navigate("/subscribe-plan");
            }, 1500);
          }
        } else {
          setBackendmessages({ error: result.error });
        }
      } catch (error) {
        console.error("Error:", error);
        setBackendmessages({
          error: "Something went wrong. Please try again.",
        });
      }

      if (signOtp) {
        setSignOtp(false); // Reset to password mode
      }
    },
  });

  const formikOtp = useFormik({
    initialValues: {
      email: "",
      otp: "",
    },
    validationSchema: otpValidationSchema,
    onSubmit: async (values, action) => {
      console.log("Submitting OTP form:", values);

      try {
        const response = await fetch(`/api/auth/otp-verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        const result = await response.json();

        if (response.ok) {
          CookieService.setCookie("token", result.token, 2);
          localStorage.setItem(
            "personal",
            JSON.stringify(result.existingUserAgg)
          );
          setBackendmessages({ success: result.message });
          action.resetForm();
          setTimeout(() => {
            navigate("/subscribe-plan");
          }, 1500);
        } else {
          setBackendmessages({ error: result.error });
        }
      } catch (error) {
        console.error("Error:", error);
        setBackendmessages({
          error: "Something went wrong. Please try again.",
        });
      }

      setSignOtp(true); // Switch to OTP mode after submission, you can modify this logic based on your requirement
    },
  });

  const toggleMode = () => {
    setSignOtp((prevMode) => !prevMode);
  };

  const isOtpMode = signOtp;
  const formik = isOtpMode ? formikOtp : formikEmailPassword;
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const handleSendOtp = async () => {
    try {
      const response = await fetch(`/api/auth/otp-send`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ value: values.email }),
      });
      console.log(values);
      const result = await response.json();

      if (response.ok) {
        setBackendmessages({ success: result.message });
        setOtpSent(true);
        setTimeLeft(2 * 60);
      } else {
        setBackendmessages({ error: result.error });
      }
    } catch (error) {
      console.error("Error:", error);
      setBackendmessages({
        error: "Something went wrong. Please try again.",
      });
    }
  };

  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(timer);
      setOtpSent(false); // Reset OTP sent status after timer ends
    }
    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}m ${secs}s`;
  };
  const handleForgot = () => {
    console.log("clicked");
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;
    const decoded = jwtDecode(token);
    const newUser = {
      email: decoded.email,
      Name: decoded.name,
      sign_in_with: "google",
    };

    try {
      const response = await fetch(`/api/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUser),
      });

      const result = await response.json();

      if (response.ok) {
        console.log(result);
        CookieService.setCookie("SignInMethod", result.sign_in_with);

        CookieService.setCookie("token", result.token, 2);
        setBackendmessages({ success: result.message });

        localStorage.setItem(
          "personal",
          JSON.stringify(result.existingUserAgg)
        );

        if (!result.existingUserAgg) {
          navigate("/ProfileCreating");
        } else {
          setTimeout(() => {
            navigate("/subscribe-plan");
          }, 1500);
        }
      } else {
        setBackendmessages({ error: result.error });
      }
    } catch (error) {
      console.error("Error:", error);
      setBackendmessages({
        error: "Something went wrong. Please try again.",
      });
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Log In</h2>
        <Message message={backendmessages} />
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Email or Phone Number"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email && (
            <p className="error">{errors.email}</p>
          )}

          {!isOtpMode && (
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )}
          {errors.password && touched.password && (
            <p className="error">{errors.password}</p>
          )}
          {isOtpMode && (
            <div className="otp-input">
              <input
                type="text"
                placeholder="OTP"
                name="otp"
                value={values.otp}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {otpSent ? (
                <span>{formatTime(timeLeft)}</span>
              ) : (
                <span
                  onClick={handleSendOtp}
                  style={{
                    cursor: "pointer",
                    color: "#458fd0",
                    textShadow: "rgb(205 232 253) 2px 2px 4px",
                  }}
                >
                  Send OTP
                </span>
              )}
            </div>
          )}
          {errors.otp && touched.otp && <p className="error">{errors.otp}</p>}
          <button type="submit" className="login-button">
            {isOtpMode ? "Sign In with Code" : "Sign In with Password"}
          </button>

          <p>OR</p>
          <button
            onClick={toggleMode}
            type="button"
            className="login-button-OTP"
          >
            {isOtpMode ? "Sign In with Password" : "Sign In with Code"}
          </button>

          <a
            href="/forgot-password"
            className="forgot-password"
            onClick={handleForgot}
          >
            Forgot your password?
          </a>
          <p className="create-acc">
            Don’t have an account? <a href="/signup">Create an account</a>
          </p>
        </form>
      </div>
      <GoogleLogin
        style={{ marginTop: "20px" }}
        onSuccess={handleGoogleSuccess}
        onError={() => {
          console.log("Login Failed");
        }}
      />
      ;
    </div>
  );
};

export default LoginPage;
