const PayTMForm = () => {
  return (
    <div className="paytm-form">
      <h2>PayTM Payment</h2>
      <form>
        <div>
          <label>Mobile Number:</label>
          <input type="text" name="mobileNumber" />
        </div>
        <button type="submit">Next</button>
      </form>
    </div>
  );
};
export default PayTMForm;