import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import Message from "./messages";
// Import the styles
import "../../styles/SignUpPage.css";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import CookieService from "encrypted-cookie";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
  otp: "",
  password: "",
  confirm_password: "",
  Mobile: "",
};

// Define the validation schema using Yup
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  Mobile: Yup.string().required("Mobile Number is required"),
});

const RegisterForm = ({ data }) => {
  const [backendmessages, setBackendmessages] = useState(null);
  const navigate = useNavigate();
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, action) => {
      console.log("cliked");
      if (!otpVerified) {
        toast.error("Verify Your Email is Mandatory");
        return;
      }

      try {
        const response = await fetch(`/api/auth/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        const result = await response.json();
        console.log(result);
        if (response.ok) {
          setBackendmessages({ success: result.message });
          console.log(values, "onsubmit");
          CookieService.setCookie("token", result.token, 2);
          action.resetForm();
          setTimeout(() => {
            navigate("/ProfileCreating");
          }, 1500);
        } else {
          setBackendmessages({ error: result.error });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  const handleGoogleSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;
    const decoded = jwtDecode(token);
    const newUser = {
      email: decoded.email,
      sign_in_with: "google",
    };

    try {
      const response = await fetch(`/api/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUser),
      });

      const result = await response.json();

      if (response.ok) {
        setBackendmessages({ success: result.message });
        CookieService.setCookie("token", result.token, 2);
        if (result.Sign_Method === "google") {
          CookieService.setCookie("SignInMethod", result.Sign_Method);
        }
        setTimeout(() => {
          navigate("/ProfileCreating");
        }, 1500);
      } else {
        setBackendmessages({ error: result.error });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSendOtp = async () => {
    const { email } = values;
    console.log(values, "avl");
    try {
      const response = await fetch(`/api/auth/otp-send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: CookieService.getCookie("token"),
        },
        body: JSON.stringify({
          value: email ? email : "",
          VerifyingEmail: true,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        setBackendmessages({ success: result.message });
        setOtpSent(true);
        setTimeLeft(2 * 60);
      } else {
        setBackendmessages({ error: result.error });
      }
    } catch (error) {
      console.error("Error:", error);
      setBackendmessages({
        error: "Something went wrong. Please try again.",
      });
    }
  };

  const handleVerifyOtp = async () => {
    const { email, otp } = values;
    console.log(otp);
    try {
      const response = await fetch(`/api/auth/otp-verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email ? email : "",
          otp: otp ? otp : "",
          message: "signupVerification",
        }),
      });

      const result = await response.json();
      console.log(result);
      if (response.ok) {
        setBackendmessages({ success: result.message });
        setOtpVerified(true);
      } else {
        setBackendmessages({ error: result.error });
      }
    } catch (error) {
      console.error("Error:", error);
      setBackendmessages({
        error: "Something went wrong. Please try again.",
      });
    }
    // setSignOtp(true);
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}m ${secs}s`;
  };
  console.log(values);

  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(timer);
      setOtpSent(false); // Reset OTP sent status after timer ends
    }
    return () => clearInterval(timer);
  }, [timeLeft]);

  const handlePhoneChange = (phone) => {
    setFieldValue("Mobile", phone);
  };

  const classSignup = data ? "sign-up-page-other" : "";

  return (
    <div className={`${classSignup} sign-up-page`}>
      <div className="sign-up-container">
        {data ? (
          <h2>SIGN UP</h2>
        ) : (
          <h2>
            Start Your <span>Journey</span>
          </h2>
        )}
        <Message message={backendmessages} />
        <form onSubmit={handleSubmit}>
          <div className="Email-input">
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {!otpVerified && !otpSent ? (
              <button
                type="button"
                onClick={handleSendOtp}
                className="profile-submit-button"
              >
                Send OTP
              </button>
            ) : (
              ""
            )}
          </div>
          {errors.email && touched.email ? <p>{errors.email}</p> : null}
          <div className="Email-input">
            {otpSent ? (
              <input
                type="number"
                placeholder="OTP"
                name="otp"
                value={values.otp}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            ) : (
              ""
            )}
            {otpVerified ? (
              <p className="verifed-text">
                Verified<i className="fas fa-check"></i>
              </p>
            ) : otpSent ? (
              <div>
                <button
                  type="button"
                  className="sign-up-verify"
                  onClick={handleVerifyOtp}
                >
                  Verify OTP
                </button>
                <span>{formatTime(timeLeft)}</span>
              </div>
            ) : (
              ""
            )}
          </div>

          {errors.otp && touched.otp ? <p>{errors.otp}</p> : null}
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.password && touched.password ? (
            <p>{errors.password}</p>
          ) : null}

          <input
            type="password"
            placeholder="Confirm Password"
            name="confirm_password"
            value={values.confirm_password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.confirm_password && touched.confirm_password ? (
            <p>{errors.confirm_password}</p>
          ) : null}

          {/* // Usage in JSX */}
          <PhoneInput
            country={"in"}
            value={values.Mobile}
            className="react-tel-input"
            onChange={handlePhoneChange}
            inputProps={{
              name: "Mobile",
              required: true,
              autoFocus: false,
            }}
          />

          {/* <PhoneInput
            country={"in"}
            value={values.Mobile}
            className="react-tel-input"
            onChange={(phone) => setFieldValue("Mobile", phone)}
            inputProps={{
              name: "Mobile",
              required: true,
              autoFocus: false,
            }}
          /> */}
          {errors.Mobile && touched.Mobile ? <p>{errors.Mobile}</p> : null}

          <button type="submit" className="sign-up-button">
            SIGN UP
          </button>
        </form>
        {data ? (
          <p className="signin-text">
            Already have an account?
            <a href="/signin"> Log In</a>
          </p>
        ) : (
          ""
        )}
      </div>
      <GoogleLogin
        style={{ marginTop: "20px" }}
        onSuccess={handleGoogleSuccess}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </div>
  );
};

export default RegisterForm;
