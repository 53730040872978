import React, { useState } from "react";
import { useFormik } from "formik";
import Message from "./messages";
import * as Yup from "yup";
import "../../styles/ForgotPasswordPage.css";
import { useNavigate, useParams } from "react-router";

const initialValues = { email: "" };
const backenduRL = process.env.REACT_APP_API_UR;
const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPassword = () => {
  const [backendmessages, setBackendmessages] = useState(null);
  const navigate = useNavigate();
  const { token } = useParams();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values, action) => {
        try {
          const response = await fetch(
            `/api/auth/forgot-password/` + token,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ password: values.password }),
            }
          );

          const result = await response.json();

          if (response.ok) {
            setBackendmessages({ success: result.message });
            action.resetForm();
            setTimeout(() => {
              navigate("/signin");
            }, 1500);
          } else {
            setBackendmessages({ error: result.error });
          }
        } catch (error) {
          console.error("Error:", error);
          setBackendmessages({
            error: "Something went wrong. Please try again.",
          });
        }
      },
    });

  return (
    <div className="forgot-password-page">
      <h2>New Password</h2>
      <Message message={backendmessages} />
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Enter New password here"
          name="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.password && touched.password && <p>{errors.password}</p>}
        <input
          type="confirm_password"
          placeholder="Confirm your password"
          name="confirm_password"
          value={values.confirm_password}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.confirm_password && touched.confirm_password && (
          <p>{errors.confirm_password}</p>
        )}

        <button type="submit" className="submit-button">
          Save Password
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
