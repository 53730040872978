import React from "react";

const Message = ({ message }) => {
  if (!message) return null;
  return (
    <p className={message.success ? "success-message" : "error-message"}>
      {message.success || message.error}
    </p>
  );
};

export default Message;
