const UPIForm = () => {
  return (
    <div className="upi-form">
      <h2>UPI Payment</h2>
      <form>
        <div>
          <label>UPI ID:</label>
          <input type="text" name="upiId" />
        </div>
        <button type="submit">Next</button>
      </form>
    </div>
  );
};
export default UPIForm;