import React from "react";
import "../../styles/uploadimg.css";
import { CiLock } from "react-icons/ci";
import { GiInfo } from "react-icons/gi";
import { MdComputer } from "react-icons/md";

const UploadImage = () => {
  return (
    <div className="container-uploadimg">
        <h1>Upload Image and get Better Matches.</h1>
      <div className="card">
        <div className="uploadpic">
          <img src={require("../../images/OIP.jpeg")} />
          <p>
            <CiLock />
            100% Privacy controls availiable
            <GiInfo />
          </p>
        </div>
        <div className="upload">
          <button>
            <MdComputer className="imagegiv" />
             {" "}Upload from Computer
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
