import React, { useState } from "react";
import { useFormik } from "formik";
import Message from "./messages";
import * as Yup from "yup";
import "../../styles/ForgotPasswordPage.css";

const initialValues = { email: "" };
const backenduRL = process.env.REACT_APP_API_UR;

const validationSchema = Yup.object({
  email: Yup.string()
    // .email("Invalid email address")
    .required("Email is required"),
});

const ForgotPasswordPage = () => {
  const [backendmessages, setBackendmessages] = useState(null);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values, action) => {
        try {
          const response = await fetch(
            `/api/auth/forgot-password`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(values),
            }
          );

          const result = await response.json();

          if (response.ok) {
            setBackendmessages({ success: result.message });
            action.resetForm();
          } else {
            setBackendmessages({ error: result.error });
          }
        } catch (error) {
          console.error("Error:", error);
          setBackendmessages({
            error: "Something went wrong. Please try again.",
          });
        }
      },
    });

  return (
    <div className="forgot-password-page">
      <h2>Forgot Password</h2>
      <Message message={backendmessages} />
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter your email"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.email && touched.email && <p>{errors.email}</p>}
        <button type="submit" className="submit-button">
          Send Reset Link
        </button>
      </form>
    </div>
  );
};

export default ForgotPasswordPage;
