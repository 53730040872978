// import React, { useState } from "react";
// import "../../styles/profileid.css";
// import CookieService from "encrypted-cookie";
// import axios from "axios";

// const ProfileId = () => {
//   const [profileId, setProfileId] = useState("");
//   const [data, setData] = useState([]);

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(profileId);

//     var custom_headers = {
//       "Content-Type": "application/json",
//       Authorization: CookieService.getCookie("token"),
//     };
//     axios
//       .get(`/api/users/GetUsersById`, {
//         params: { id: profileId },
//         headers: custom_headers,
//       })
//       .then((response) => {
//         setData(response.data);
//         const data = response.data;
//       })
//       .catch((error) => {
//         console.error("Error fetching users data:", error);
//       });
//   };

//   // Handle input change
//   const handleChange = (e) => {
//     setProfileId(e.target.value);
//   };

//   return (
//     <div className="bottom-section">
//       <form onSubmit={handleSubmit}>
//         <input
//           id="studentId"
//           type="text"
//           placeholder="Enter Profile Id Here.."
//           name="Profileid"
//           value={profileId}
//           onChange={handleChange}
//         />

//         <button type="submit">Submit</button>
//       </form>
//       {data ? data.map((each) => <div>{each}</div>) : ""}
//     </div>
//   );
// };

// export default ProfileId;

import React, { useState } from "react";
import "../../styles/profileid.css";
import CookieService from "encrypted-cookie";
import axios from "axios";

const ProfileId = () => {
  const [profileId, setProfileId] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState(null);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const value = String(profileId).slice(3);

    const Id = parseInt(value);

    if (!Id) {
      console.error("Profile ID is required");
      return;
    }

    setLoading(true);
    setError(null);

    var custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };
    axios
      .get(`/api/users/GetUsersById`, {
        params: { id: Id },
        headers: custom_headers,
      })
      .then((response) => {
        setData(response.data.users);
        if (response.data.images) {
          setImg(response.data.images);
        }
      })
      .catch((error) => {
        setError("Error fetching users data. Please try again.");
        console.error("Error fetching users data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Handle input change
  const handleChange = (e) => {
    setProfileId(e.target.value);
  };

  return (
    <div className="bottom-section">
      <form onSubmit={handleSubmit}>
        <input
          id="studentId"
          type="text"
          placeholder="Enter Profile Id Here.."
          name="Profileid"
          value={profileId}
          onChange={handleChange}
        />

        <button type="submit" disabled={loading}>
          {loading ? "Loading..." : "Submit"}
        </button>
      </form>

      {error && <p className="error-message">{error}</p>}

      {data.length > 0
        ? data.map((each, index) => (
            <div key={index} className="profile-user">
              {img ? (
                <img
                  
                  src={`data:image/jpeg;base64,${img[0]}`}
                  alt={`Profile ${img._id} image 1`}
                />
              ) : (
                <img src={require("../../images/OIP.jpeg")} />
              )}
              <div className="profile-info">
                <h2>{each.Name}</h2>
                <p>Age: {each.Age}</p>
                <p>Denomination: {each.Denomination}</p>
                <p>Bride or Groom: {each["Bride/Bridegroom"]}</p>
                <p>Cast: {each.Cast}</p>
                <p>Marital Status: {each["Marital Status"]}</p>
                <p>City: {each.City}</p>
                <p>Salary: {each["Salary-PA"] || "Not-Specified"}</p>
              </div>
            </div>
          ))
        : ""}
    </div>
  );
};

export default ProfileId;
