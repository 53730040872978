import React, { useState } from "react";
import "../../styles/FilteredList.css";
import Modal from "./model";

const Filteredlist = (props) => {
  const { data } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="user-list">
      <div className="user-details">
        {currentData.map((each) => (
          <div key={each._id} className="each-profile">
            <Modal data={each} />
            <div className="profile-info">
              <h2>{each.Name}</h2>
              <h4>
                <span>JIM</span>
                {each.JIOID}
               
              </h4>
              <p>Age: {each.Age}</p>
              <p>Denomination: {each.Denomination}</p>
              <p>Bride or Groom: {each["Bride/Bridegroom"]}</p>
              <p>Cast: {each.Cast}</p>
              <p>Marital Status: {each["Marital Status"]}</p>
              <p>City: {each.City}</p>
              <p>Salary: {each["Salary-PA"] || "Not-Specified"}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Filteredlist;
