const DebitCardForm = () => {
  return (
    <div className="debit-card-form">
      <h2>Debit Card Payment</h2>
      <form>
        <div>
          <label>Card Number:</label>
          <input type="text" name="cardNumber" />
        </div>
        <div>
          <label>Expiry Date:</label>
          <input type="text" name="expiryDate" />
        </div>
        <div>
          <label>CVV:</label>
          <input type="text" name="cvv" />
        </div>
        <div>
          <label>Cardholder Name:</label>
          <input type="text" name="cardholderName" />
        </div>
        <button type="submit">Next</button>
      </form>
    </div>
  );
};
export default DebitCardForm;