import React, { useState, useEffect, useContext } from "react";
import "../../styles/Home.css";
import HowItWorks from "./HowItWorks.js";
import FeaturedProfiles from "./FeaturedProfiles.js";
import WhyAreWeBetter from "./WhyAreWeBetter.js";
import Testimonials from "./Testimonials.js";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Filteredlist from "../Dashboard/filteredlist.js";
import CookieService from "encrypted-cookie";

import { UserContext } from "../../context/UserContext.js"; // Adjust the path accordingly

const backenduRL = process.env.REACT_APP_API_UR;
function HomePage() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const [filterdata, setFilterdata] = useState({
    ageFrom: 22,
    ageTo: 27,
  });
  const [religionOptions, setReligionOptions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [maritial, setMaritial] = useState([]);
  const [salaryOptions] = useState([
    "Below 2LPA",
    "2 LPA - 5 LPA",
    "5 LPA - 10 LPA",
    "Above 10LPA",
  ]);
  const { userDetails } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    const token = CookieService.checkCookie("token");

    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    var custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };
    axios
      .get(`/api/users/getDetails`, { headers: custom_headers })
      .then((response) => {
        const data = response.data;
        console.log(data);
        const uniqueReligions = [
          ...new Set(data.map((user) => user.Denomination)),
        ];
        const uniqueCastes = [...new Set(data.map((user) => user.Cast))];
        const uniqueRegions = [...new Set(data.map((user) => user.Country))];
        const uniqueCities = [...new Set(data.map((user) => user.City))];
        const uniqueMaritial = [
          ...new Set(data.map((user) => user["Marital Status"])),
        ];

        setReligionOptions(uniqueReligions);
        setCasteOptions(uniqueCastes);
        setRegionOptions(uniqueRegions);
        setCityOptions(uniqueCities);
        setMaritial(uniqueMaritial);
      })
      .catch((error) => {
        console.error("Error fetching users data:", error);
      });
  }, []);

  const handleFilter = async () => {
    if (!isAuthenticated) {
      setTimeout(() => {
        navigate("/signin");
      }, 1500);
      return;
    }
    var custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };
    try {
      const response = await axios.get(`/api/users/usersdetails`, {
        params: filterdata,
        headers: custom_headers,
      });
      const data = response.data;
      setUserdata(data);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFilterdata({
      ...filterdata,
      [name]: value,
    });
  };

  const classnameforhomepage = isAuthenticated ? "height-Mantain" : "";
  return (
    <div className="Main-content">
      <div className={`${classnameforhomepage} homepage`}>
        {isAuthenticated ? (
          <div className="header">
            <h1 className="title">The World's No.1 Matrimonial Service</h1>
            <div className="search-box">
              <div className="search-field">
                <label className="im">I'm looking for a</label>
                <select name="Bride/Bridegroom" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="Bridegroom">Bridegroom</option>
                  <option value="Bride">Bride</option>
                </select>
              </div>
              <div className="search-field">
                <label>Age</label>
                <input
                  type="number"
                  defaultValue="22"
                  min="18"
                  value={filterdata.ageFrom}
                  name="ageFrom"
                  onChange={handleInputChange}
                />
                <span>to</span>
                <input
                  type="number"
                  defaultValue="27"
                  min="18"
                  name="ageTo"
                  value={filterdata.ageTo}
                  onChange={handleInputChange}
                />
              </div>
              <div className="search-field">
                <label>Denomination</label>
                <select name="Denomination" onChange={handleInputChange}>
                  <option>Select</option>
                  {religionOptions.map((religion, index) => (
                    <option key={index} value={religion}>
                      {religion}
                    </option>
                  ))}
                </select>
              </div>
              <div className="search-field">
                <label>Cast</label>
                <select name="Cast" onChange={handleInputChange}>
                  <option>Select</option>
                  {casteOptions.map((caste, index) => (
                    <option key={index} value={caste}>
                      {caste}
                    </option>
                  ))}
                </select>
              </div>

              <div className="search-field">
                <label>Marital Status</label>
                <select name="MaritalStatus" onChange={handleInputChange}>
                  <option>Select</option>
                  {maritial.map((status, index) => (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>

              <div className="search-field">
                <label>City</label>
                <select name="City" onChange={handleInputChange}>
                  <option>Select</option>
                  {cityOptions.map((City, index) => (
                    <option key={index} value={City}>
                      {City}
                    </option>
                  ))}
                </select>
              </div>

              <div className="search-field">
                <label>Country</label>
                <select name="Country" onChange={handleInputChange}>
                  <option>Select</option>
                  {regionOptions.map((region, index) => (
                    <option key={index} value={region}>
                      {region}
                    </option>
                  ))}
                </select>
              </div>

              <div className="search-field">
                <label>Salary</label>
                <select name="salary" onChange={handleInputChange}>
                  <option>Select</option>
                  {salaryOptions.map((salary, index) => (
                    <option key={index} value={salary}>
                      {salary}
                    </option>
                  ))}
                </select>
              </div>

              <button className="search-button" onClick={handleFilter}>
                Let's Begin
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {isAuthenticated ? <Filteredlist data={userdata} /> : ""}

      <FeaturedProfiles />
      <HowItWorks />
      <WhyAreWeBetter />
      <Testimonials />
    </div>
  );
}

export default HomePage;
