import React, { useState } from "react";
import "../../styles/WhyAreWeBetter.css";
import giftopen from "../../images/giftopen.jpg";
import giftclose from "../../images/giftclose.jpg";
import bulbon from "../../images/bulbon.jpg";
import bulboff from "../../images/bulboff.jpg";
import yoga from "../../images/yoga.jpg";

const WhyAreWeBetter = () => {
  const [hoveredCard, setHoveredCard] = useState(null); // State to track the hovered card

  const benefits = [
    {
      icon: giftclose,
      hoverIcon: giftopen, // Image to show on hover
      title: "Exclusive Deals",
      description: "Best deals made only for you tailored to your preferences.",
    },
    {
      icon: bulboff,
      hoverIcon: bulbon, // Image to show on hover
      title: "Expert Insights",
      description: "Our wedding experts know how to craft the best for you.",
    },
    {
      icon: yoga, // Use the same image for both hover and normal state if no change is required
      hoverIcon: yoga,
      title: "Stress-free Experience",
      description:
        "From venue recce to last second of your wedding, we’ll be with you.",
    },
  ];

  return (
    <div className="why-we-are-better-sec">
      <section className="why-are-we-better">
        <h2>What Makes Us Different</h2>
        <p>
          Because we bring our years of experience in planning your wedding.
        </p>
        <div className="benefits">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="benefit-card"
              onMouseEnter={() => setHoveredCard(index)} // Set hover state on mouse enter
              onMouseLeave={() => setHoveredCard(null)} // Reset hover state on mouse leave
            >
              <img
                src={hoveredCard === index ? benefit.hoverIcon : benefit.icon}
                alt={benefit.title}
              />
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </div>
          ))}
        </div>
      </section>
      <img src={require("../../images/wave.png")} />
    </div>
  );
};

export default WhyAreWeBetter;
