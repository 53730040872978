const NetBankingForm = () => {
  return (
    <div className="net-banking-form">
      <h2>Net Banking Payment</h2>
      <form>
        <div>
          <label>Select Bank:</label>
          <select name="bank">
            <option value="sbi">State Bank of India</option>
            <option value="icici">ICICI Bank</option>
            <option value="hdfc">HDFC Bank</option>
            
          </select>
        </div>
        <button type="submit">Next</button>
      </form>
    </div>
  );
};
export default NetBankingForm;