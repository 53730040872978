import React from 'react';
import '../../styles/HowItWorks.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import { Link } from 'react-router-dom';

const HowItWorks = () => {
  return (
    <section id="Howitworks" className="how-it-works">
      <div className="head">
        <img src={require("../../images/sideline.webp")} />
        <h2>
          Find your <span>Special</span> Someone
        </h2>
        <img src={require("../../images/sideline.webp")} />
      </div>
      <div className="steps">
        <div className="step">
          <div className="icon bg-cyan">
            <i className="fas fa-crown"></i>
          </div>
          <Link to="/subscribe-plan">
            <h3>Subscribe Now</h3>
          </Link>
          <p>
            Upgrade your experience with a Jio Matrimony and and unlock
            exclusive features..
          </p>
        </div>

        <div className="step">
          <div className="icon bg-cyan">
            <i className="fas fa-search"></i>
          </div>
          <Link to="/profileid">
            <h3>Connect</h3>
          </Link>

          <p>Select & Connect with Matches you like.</p>
        </div>
        <div className="step">
          <div className="icon bg-cyan">
            <i className="fas fa-comments"></i>
          </div>
          <h3>Interact</h3>
          <p>Become a Premium Member & Start a Conversation.</p>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
