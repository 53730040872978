import React, { useState } from "react";
import "../../styles/Footer.css";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { SlArrowDown } from "react-icons/sl";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome
import { Link } from "react-router-dom";

const Footer = () => {
  const [showAbout, setShowAbout] = useState(false);
  const [showQuickLinks, setShowQuickLinks] = useState(false);
  const [showHelpSupport, setShowHelpSupport] = useState(false);
  const [showContact, setShowContact] = useState(false);
  return (
    <>
      <img
        className="border-footer"
        src={require("../../images/green-curve.png")}
      />
      <footer className="footer">
        <img className="img1" src={require("../../images/flower2.png")} />
        <div className="content">
          <div className="footer-top">
            {/* <img src={require("../../images/JIOMLOGO2.jpg")} /> */}

            <h1 className="footer-logo">
              Jio{" "}
              <span>Matrimony (Unit Of Spec Computers And Communications)</span>
            </h1>

            <p>
              Leading the way in global matchmaking services, Matrimony provides
              a secure and reliable platform to find life partners with ease.
            </p>
          </div>
          {/* <hr /> */}
          <div className="footer-content">
            <div
              className={`footer-section about ${showAbout ? "active" : ""}`}
            >
              <div className="resp-arr">
                <h2>About JioMatrimony</h2>
                <SlArrowDown
                  onClick={() => setShowAbout(!showAbout)}
                  className="icon-toshow"
                />
              </div>

              <p className="about-jio">
                At <span>Jio Matrimony</span>, we are committed to helping
                individuals find their perfect match. Proudly registered under
                our parent company,
                <span>Spec Computers and Communications</span>, we bring the
                same level of dedication, integrity, and innovation that has
                defined our legacy. Whether you're seeking a life partner or
                companionship, <span>Jio Matrimony</span> offers a reliable
                platform to connect and build meaningful relationships. Join us
                on this journey to discover love and happiness.
              </p>
            </div>
            {/* <div
              className={`footer-section links ${
                showQuickLinks ? "active" : ""
              }`}
            >
              <div className="resp-arr">
                <h2>Quick Links</h2>
                <SlArrowDown
                  onClick={() => setShowQuickLinks(!showQuickLinks)}
                  className="icon-toshow"
                />
              </div>
              <ul>
                <li>
                  <a href="/dashboard">Home</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/services">Services</a>
                </li>
                <li>
                  <a href="/ContactEmail">Contact</a>
                </li>
              </ul>
            </div> */}
            <div
              className={`footer-section links ${
                showHelpSupport ? "active" : ""
              }`}
            >
              <div className="resp-arr">
                <h2>Help & Support</h2>
                <SlArrowDown
                  onClick={() => setShowHelpSupport(!showHelpSupport)}
                  className="icon-toshow"
                />
              </div>
              <ul>
                <li>
                  <a href="/dashboard">24X7 Live Help</a>
                </li>
                <li>
                  <a href="/dashboard">Contact Us</a>
                </li>
                <li>
                  <a href="/dashboard">Feedback</a>
                </li>
                <li>
                  <a href="/dashboard">FAQs</a>
                </li>
              </ul>
            </div>
            <div
              className={`footer-section contact ${
                showContact ? "active" : ""
              }`}
            >
              <div className="resp-arr">
                <h2>Contact Us</h2>
                <SlArrowDown
                  onClick={() => setShowContact(!showContact)}
                  className="icon-toshow"
                />
              </div>
              <ul>
                <li>Email: contact@jiomatrimony.com</li>
                <li>Phone: +91-7893206880</li>
                <li>
                  Address: Room No.203, Second floor, Plot No.1588, D.No.9-1-134
                  to 137, Amruthavani Communication Centre, Sebastian Road,
                  Patny Center Circle, Secunderabad, Hyderabad, Telangana,
                  500003
                </li>
              </ul>
            </div>
          </div>
          <div className="Footer-bottom-section">
            <div className="footer-bottom">
              <p>&copy; 2024 Matrimony. All rights reserved.</p>
            </div>
            <div className="footer-links">
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
              <a href="/terms-and-conditions" target="_blank">
                Terms and Conditions
              </a>
              <a href="/refund-policy" target="_blank">
                Refund Policies
              </a>
            </div>
            <div className="footer-section social">
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=61564390979591&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookSquare className="about-us-2-social-fb about-us-2-social" />
                </a>
                <a
                  href="https://x.com/jio_matrimony"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaSquareXTwitter className="about-us-2-social-x about-us-2-social" />
                </a>
                <a
                  href="https://www.instagram.com/jio_matrimony/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagramSquare className="about-us-2-social-insta about-us-2-social" />
                </a>
                <a
                  href="https://www.linkedin.com/company/jiomatrimony/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin className="about-us-2-social-linkedin about-us-2-social" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <img className="img2" src={require("../../images/flower1.png")} />
      </footer>
    </>
  );
};

export default Footer;
